import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { formatDateTimeLocal, getCustomerLink } from 'services/utils';
import Button from 'uikit/Button/Button';
import './TopicMessagesTableRow.scss';
import { MESSAGE_SENDER_TYPES } from '../../../../../../components/constants';

const TopicMessagesTableRow = ({ isLoading, messageData, topicData, downloadMessageDocument }) => {
  const handleDownloadMessageDocument = async (documentId) => {
    await downloadMessageDocument(documentId);
  };

  const getTopicInfoBlock = (topicBlockInfo, accountData, isMessageBody) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'topic-messages-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (accountData) {
      return (
        <NavLink
          className={'topic-message-id-text'}
          to={getCustomerLink(accountData.customerNumber, accountData.customerStatus, accountData.customerType)}
        >
          {topicBlockInfo}
        </NavLink>
      );
    } else if (Array.isArray(topicBlockInfo) && !isMessageBody) {
      return (
        <div className={'topic-message-id-text'}>
          {topicBlockInfo.map((document, index) => {
            if (document) {
              return (
                <Fragment key={index}>
                  <Button
                    className={'link-button topic-message-id-text message-document-button'}
                    onClick={async () => await handleDownloadMessageDocument(document.id)}
                    isDisabled={false}
                  >
                    {document.name}
                  </Button>
                  {index < topicBlockInfo.length - 1 && <br />}
                </Fragment>
              );
            }
          })}
        </div>
      );
    } else {
      return <div className="text-container">{topicBlockInfo}</div>;
    }
  };

  return (
    <tr>
      <td className={'topic-messages-table-cell message-table-cell'}>
        <div className={"message-header"}>
          <div className={"message-author"}>
            {messageData.senderType === MESSAGE_SENDER_TYPES.USER
                ? getTopicInfoBlock(topicData.accountName, {
                  customerNumber: topicData.accountNumber,
                  customerName: topicData.accountName,
                  customerType: topicData.accountType,
                  customerStatus: topicData.accountStatus
                })
                : getTopicInfoBlock(messageData.senderOperatorName)}
          </div>
          <div className={"message-date"}>
            {getTopicInfoBlock(formatDateTimeLocal(messageData.createdAt))}
          </div>
        </div>
        {getTopicInfoBlock(parse(messageData.messageBody), undefined, true)}
        <div className={"message-attachments"}>
          Attachments ({messageData?.documents?.length || 0})
          {getTopicInfoBlock(messageData.documents)}
        </div>
      </td>
    </tr>
  );
};

TopicMessagesTableRow.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  messageData: PropTypes.object.isRequired,
  topicData: PropTypes.object.isRequired,
  downloadMessageDocument: PropTypes.func.isRequired
};

export default TopicMessagesTableRow;

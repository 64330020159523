import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { CUSTOMER_TYPE, DEFAULT_COUNTRY_CODE, DEFAULT_DIAL_CODE, EXCLUDED_COUNTRIES } from 'components/constants';
import i18nContext from 'components/i18n-context';
import Loader from 'components/Loader';
import { emailRegExp, getCustomerLink, noNumbersRegExp } from 'services/utils';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import PasswordInput from 'uikit/PasswordInput';
import './CreateUser.scss';

const CreateUser = ({ usersStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      phone: DEFAULT_DIAL_CODE,
      email: '',
      password: '',
      customerType: CUSTOMER_TYPE.CORPORATE,
      companyName: '',
      firstName: '',
      lastName: ''
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      phone: Yup.string()
        .nullable()
        .required(i18n.getMessage('validation.emptyField'))
        .phone(countryCode.toUpperCase(), i18n.getMessage('validation.invalidPhone')),
      email: Yup.string()
        .nullable()
        .required(i18n.getMessage('validation.emptyField'))
        .max(50, i18n.getMessage('validation.fieldSize', { amount: '50' }))
        .trim()
        .matches(emailRegExp, { message: i18n.getMessage('validation.invalidEmail') }),
      password: Yup.string()
        .nullable()
        .required(i18n.getMessage('validation.emptyField'))
        .test(
          'valid-characters',
          i18n.getMessage('error.REGISTRATION_PASSWORD_REQUIREMENTS'),
          (value) => !/[^\x20-\x7E]/.test(value)
        ),
      companyName: Yup.string().when('customerType', {
        is: (has) => has === CUSTOMER_TYPE.CORPORATE,
        then: () =>
          Yup.string()
            .trim()
            .required(i18n.getMessage('validation.emptyField'))
            .max(250, i18n.getMessage('validation.fieldSize', { amount: '250' })),
        otherwise: () => Yup.string().nullable()
      }),
      firstName: Yup.string().when('customerType', {
        is: (has) => has === CUSTOMER_TYPE.INDIVIDUAL,
        then: () =>
          Yup.string()
            .trim()
            .required(i18n.getMessage('validation.emptyField'))
            .max(250, i18n.getMessage('validation.fieldSize', { amount: '250' }))
            .matches(noNumbersRegExp, { message: i18n.getMessage('validation.noNumbersAllowed') }),
        otherwise: () => Yup.string().nullable()
      }),
      lastName: Yup.string().when('customerType', {
        is: (has) => has === CUSTOMER_TYPE.INDIVIDUAL,
        then: () =>
          Yup.string()
            .trim()
            .required(i18n.getMessage('validation.emptyField'))
            .max(250, i18n.getMessage('validation.fieldSize', { amount: '250' }))
            .matches(noNumbersRegExp, { message: i18n.getMessage('validation.noNumbersAllowed') }),
        otherwise: () => Yup.string().nullable()
      })
    }),
    onSubmit: async (values) => {
      if (isPasswordVerified) {
        await usersStore.createUser(values);
      }
    }
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue, submitCount, validateField, setFieldError } = form;

  const customerTypeOptions = Object.keys(CUSTOMER_TYPE).map((type) => {
    return {
      key: CUSTOMER_TYPE[type],
      value: i18n.getMessage('customerType.' + CUSTOMER_TYPE[type])
    };
  });

  const handleChangeCustomerType = (name, value) => {
    setFieldValue(name, value);

    if (value === CUSTOMER_TYPE.CORPORATE) {
      setFieldValue('firstName', '');
      setFieldValue('lastName', '');
      setFieldError('firstName', undefined);
      setFieldError('lastName', undefined);
    } else if (value === CUSTOMER_TYPE.INDIVIDUAL) {
      setFieldValue('companyName', '');
      setFieldError('companyName', undefined);
    }
  };

  useEffect(() => {
    if (usersStore.isNewUserSuccessfullyCreated) {
      navigate(
        getCustomerLink(
          usersStore?.newCustomerInfo?.account_number,
          usersStore.newCustomerInfo.status,
          usersStore.newCustomerInfo.type
        )
      );
      usersStore.setIsNewUserSuccessfullyCreated(false);
      usersStore.resetNewCustomerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersStore.isNewUserSuccessfullyCreated]);

  return (
    <Container className={'create-user-container'} header={i18n.getMessage('container.createUser')}>
      <form action={'/form'} autoComplete={'off'} id={'send'} onSubmit={handleSubmit} className={'create-user-form'}>
        <div className={'create-user-form-inputs-wrapper'}>
          <Input
            className={'create-user-form-input'}
            type={'phone'}
            label={i18n.getMessage('user.create.input.label.phone')}
            name={'phone'}
            autoComplete={'off'}
            value={values.phone}
            setValue={setFieldValue}
            onChange={handleChange}
            initialStatus={submitCount}
            setCountryCode={setCountryCode}
            excludeCountries={EXCLUDED_COUNTRIES}
            error={errors?.phone || usersStore.error?.phone}
          />
          <Input
            className={'create-user-form-input'}
            type={'email'}
            name={'email'}
            label={i18n.getMessage('user.create.input.label.email')}
            autoComplete={'new-email'}
            value={values.email}
            onChange={handleChange}
            initialStatus={submitCount}
            error={errors?.email || usersStore.error?.email}
          />
          <PasswordInput
            // eslint-disable-next-line max-len
            className={`${errors?.password ? 'create-user-form-password-input-error' : 'create-user-form-password-input'}`}
            name={'password'}
            label={i18n.getMessage('user.create.input.label.password')}
            autoComplete={'new-password'}
            value={values.password}
            onChange={handleChange}
            onBlur={() => validateField('password')}
            setIsPasswordVerified={setIsPasswordVerified}
            initialStatus={submitCount}
            error={errors?.password || usersStore.error?.password}
          />
          <InputDropDown
            className={'create-user-form-input'}
            label={i18n.getMessage('user.create.input.label.customerType')}
            id={'customerType'}
            name={'customerType'}
            value={values.customerType}
            options={customerTypeOptions}
            onChange={handleChangeCustomerType}
          />
          {values.customerType === CUSTOMER_TYPE.CORPORATE && (
            <Input
              className={'create-user-form-input'}
              type={'text'}
              name={'companyName'}
              label={i18n.getMessage('user.create.input.label.companyName')}
              autoComplete={'off'}
              value={values.companyName}
              onChange={handleChange}
              initialStatus={submitCount}
              error={errors?.companyName || usersStore.error?.companyName}
            />
          )}
          {values.customerType === CUSTOMER_TYPE.INDIVIDUAL && (
            <Input
              className={'create-user-form-input'}
              type={'text'}
              name={'firstName'}
              label={i18n.getMessage('user.create.input.label.firstName')}
              autoComplete={'off'}
              value={values.firstName}
              onChange={handleChange}
              initialStatus={submitCount}
              error={errors?.firstName || usersStore.error?.firstName}
            />
          )}
          {values.customerType === CUSTOMER_TYPE.INDIVIDUAL && (
            <Input
              className={'create-user-form-input'}
              type={'text'}
              name={'lastName'}
              label={i18n.getMessage('user.create.input.label.lastName')}
              autoComplete={'off'}
              value={values.lastName}
              onChange={handleChange}
              initialStatus={submitCount}
              error={errors?.lastName || usersStore.error?.lastName}
            />
          )}
          <Button
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={true}
            onClick={() => {}}
            isDisabled={usersStore.isLoading}
          >
            {usersStore.isLoading ? <Loader /> : i18n.getMessage('user.create.button.create')}
          </Button>
        </div>
      </form>
    </Container>
  );
};

CreateUser.propTypes = {
  usersStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  usersStore: stores.usersStore
}))(observer(CreateUser));

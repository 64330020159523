import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import queryString from 'query-string';
import Table from 'react-bootstrap/Table';
import TopicMessagesTableHead from './components/TopicMessagesTableHead';
import TopicMessagesTableRow from './components/TopicMessagesTableRow';
import TopicMessageForm from './components/TopicMessageForm';
import i18nContext from 'components/i18n-context';
import { MENU_DIRECTION, MESSAGE_TOPIC_STATUS } from 'components/constants';
import Loader from 'components/Loader';
import OperatorsAsyncSelect from 'components/OperatorsAsyncSelect';
import Pagination from 'components/Pagination/Pagination';
import { Container } from 'uikit/Container/Container';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './TopicMessages.scss';

const TopicMessages = ({ messagesStore }) => {
  const i18n = useContext(i18nContext);
  const { id: topicId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const messagesTopicStatusesOptions = Object.keys(MESSAGE_TOPIC_STATUS).map((status) => {
    return {
      key: MESSAGE_TOPIC_STATUS[status],
      value: i18n.getMessage('messages.topics.status.' + MESSAGE_TOPIC_STATUS[status])
    };
  });

  const updateFiltersInUrl = (pagination) => {
    const queryParams = {
      page: pagination.page,
      size: pagination.size
    };
    const searchParams = queryString.stringify(queryParams);
    navigate({ search: `?${searchParams}` }, { replace: true });
  };

  const loadFiltersFromUrl = () => {
    const params = queryString.parse(location.search);
    messagesStore.setFiltersFromUrl(params);
  };

  useEffect(() => {
    loadFiltersFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!messagesStore.isInitialized) {
        await messagesStore.getTopicById(topicId).then(() => messagesStore.getTopicMessages(topicId));
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesStore.isInitialized]);

  useEffect(() => {
    if (messagesStore.isInitialized) {
      messagesStore.resetMessagesStore(topicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    updateFiltersInUrl(messagesStore.pagination);
    if (messagesStore.isInitialized) {
      messagesStore.getTopicMessages(topicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesStore.pagination.size, messagesStore.pagination.page]);

  const handleChangeOwnerOperator = async (value) => {
    await messagesStore.updateTopicOwnerOperator(topicId, value?.id);
  };

  const handleChangeTopicStatus = async (name, value) => {
    await messagesStore.updateTopicStatus(topicId, value);
  };

  if (
    !messagesStore.isInitialized ||
    !messagesStore.currentTopicInfo ||
    messagesStore.currentTopicMessages.length === 0
  ) {
    return <Loader className={'application-loader'} />;
  }

  return (
      <Container
          className={'topic-messages-container'}
          header={i18n.getMessage('container.topicMessages', {subject: messagesStore.currentTopicInfo.subject})}
      >
        <div className={'topic-options-wrapper'}>
          <div className={'topic-option-block'}>
          <span className={'topic-option-label'}>
            {i18n.getMessage('messages.topicMessages.table.select.ownerOperator')}
          </span>
            <OperatorsAsyncSelect
                className={'topic-option-select'}
                handleValueChanged={handleChangeOwnerOperator}
                value={messagesStore.currentTopicInfo?.ownerOperator}
            />
          </div>
          <div className={'topic-option-block'}>
          <span className={'topic-option-label'}>
            {i18n.getMessage('messages.topicMessages.table.select.topicStatus')}
          </span>
            <InputDropDown
                className={'topic-option-select'}
                id={'topicStatus'}
                name={'topicStatus'}
                options={messagesTopicStatusesOptions}
                value={messagesStore.currentTopicInfo?.status}
                onChange={handleChangeTopicStatus}
            />
          </div>
        </div>

        <Pagination
            isShowPaginationSize={true}
            paginationSize={messagesStore.pagination.size}
            handleChangePaginationSize={messagesStore.setPageSize}
            isLoading={messagesStore.isLoading}
            currentPage={messagesStore.pagination.page}
            numberOfPages={messagesStore.pagination.totalPages}
            setCurrentPage={messagesStore.setPageNumber}
        />


        <div className={"chat-container"}>
          <Table responsive>
            <thead>
            <TopicMessagesTableHead/>
            </thead>
            <tbody>
            {messagesStore.currentTopicMessages?.map((messageData, index) => (
                <TopicMessagesTableRow
                    key={index}
                    type={'list'}
                    isLoading={messagesStore.isLoading}
                    messageData={messageData}
                    topicData={messagesStore.currentTopicInfo}
                    downloadMessageDocument={messagesStore.downloadTopicDocument}
                />
            ))}
            </tbody>
          </Table>

          <Pagination
              isShowPaginationSize={true}
              paginationSize={messagesStore.pagination.size}
              handleChangePaginationSize={messagesStore.setPageSize}
              isLoading={messagesStore.isLoading}
              currentPage={messagesStore.pagination.page}
              numberOfPages={messagesStore.pagination.totalPages}
              setCurrentPage={messagesStore.setPageNumber}
              menuDirection={MENU_DIRECTION.UP}
          />

          <TopicMessageForm
              isLoading={messagesStore.isLoading}
              isFileUploading={messagesStore.isFileUploading}
              isNewMessageCreated={messagesStore.isNewMessageCreated}
              setIsNewMessageCreated={messagesStore.setIsNewMessageCreated}
              uploadedFiles={messagesStore.uploadedFiles}
              sendTopicMessage={messagesStore.sendTopicMessage}
              topicId={topicId}
              uploadAttachments={messagesStore.uploadAttachments}
              removeAttachment={messagesStore.removeAttachment}
          />
        </div>
      </Container>
  );
};

TopicMessages.propTypes = {
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  messagesStore: stores.messagesStore
}))(observer(TopicMessages));

import { CUSTOMER_STATUSES, CUSTOMER_TYPE } from 'components/constants';
import numeral from 'numeral';
import moment from 'moment-timezone';
import i18n from 'i18n';

export const emailRegExp =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
export const passwordSpecialSymbolsRegExp = /[!\"#$%&'()*+,-.:;<=>?@\[\]^_`{/|\\}~]/;
export const noNumbersRegExp = /^[^\d]*$/;
export const cryptoCurrencies = ['ETH', 'FNMC'];

export const convertBytesToMegabytes = (bytes) => bytes / (1024 * 1024);

export const isCryptoCurrency = (currency) => cryptoCurrencies.includes(currency.toUpperCase());

export const getCustomerLink = (customerNumber, customerStatus, customerType) => {
  return `/${customerStatus === CUSTOMER_STATUSES.VERIFIED ? 'active-customers' : 'customers'}/${
    customerType === CUSTOMER_TYPE.INDIVIDUAL ? 'individual' : 'corporative'
  }/${customerNumber}?tab=customerDetails`;
};

export const formatDate = (utcDate) => {
  return utcDate ? moment.utc(utcDate).format('DD/MM/YYYY') : null;
};

export const formatTime = (utcDate) => {
  return utcDate ? moment.utc(utcDate).format('HH:mm') + ' UTC' : null;
};

export const formatDateTime = (utcDate) => {
  return utcDate ? moment.utc(utcDate).format('DD/MM/YYYY HH:mm') + ' UTC' : null;
};

export const formatDateTimeLocal = (utcDate) => {
  return utcDate ? moment.utc(utcDate).local().format('DD/MM/YYYY HH:mm') : null;
};

export const convertDate = (date) => {
  if (date) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }`;
  }

  return date;
};

export const convertDateWithTime = (date, isEndOfDay = false) => {
  if (date) {
    return `${date.getFullYear()}-${date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    }T${isEndOfDay ? '23:59:59' : '00:00:00'}`;
  }
  return date;
};

export const convertToFullDate = (dateStr) => {
  if (!dateStr) return;

  if (dateStr.includes('T')) {
    return new Date(dateStr);
  }

  const [year, month, day] = dateStr.split('-').map(Number);

  return new Date(year, month - 1, day); // months are 0-indexed
};

export const debounce = (func, delay = 400) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      return func.apply(context, args);
    }, delay);
  };
};

export const MEDIA_QUERY = {
  HEADER_BUTTON_NAMES: '(min-width: 1050px)',
  DESKTOP: '(min-width: 769px)',
  NON_DESKTOP: '(min-width: 320px)',
  MOBILE: '(max-width: 499px)'
};

export const amountFormattedValue = (value, precision = 2) => {
  if (value === undefined || value === '' || value === null) {
    return '';
  }

  let stringValue = value.toString();

  const dotIndex = stringValue.indexOf('.');

  if (dotIndex !== -1) {
    stringValue = stringValue.slice(0, dotIndex + precision + 1);
  }

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  }).format(Number(stringValue));
};

export const format = (val) => {
  const formatted = val?.split('_').join(' ').toLowerCase();
  const capitalized = formatted?.charAt(0).toUpperCase() + formatted?.slice(1);
  return capitalized;
};

export const formatNumber = (number, precision) => {
  if (number === undefined || number === '') {
    return '';
  }

  let fractionalSize = '';
  for (let i = 0; i < precision; i++) {
    fractionalSize += '0';
  }

  return numeral(number).format('0,00.' + fractionalSize);
};
export const formatMoney = (amount, currency = '', precision = 2) => {
  if (amount === undefined || amount === null) {
    return '';
  }

  if (!currency || !isCryptoCurrency(currency)) {
    return formatNumber(amount, precision);
  }
  return Number(amount).toFixed(10);
};

export const regularFieldRegExp = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~\s]*$/;
export const countryCodeRegExp = /^[A-Z]+$/;

export const parseExternalKycState = (kycState) => {
  if (!kycState) {
    return {
      statuses: '',
      tags: '',
      identifier: ''
    };
  }

  const { tags, initiated_at, approved_at, rejected_at, identifier } = kycState;
  const statuses = [];
  let parsedTags = '';
  let parsedStatuses = '';

  if (initiated_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.documentsRequested', {
        date: formatDate(initiated_at)
      })
    );
  }
  if (approved_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.approved', {
        date: formatDate(approved_at)
      })
    );
  }
  if (rejected_at) {
    statuses.push(
      i18n.getMessage('customers.externalKycProvider.status.rejected', {
        date: formatDate(rejected_at)
      })
    );
  }

  if (tags) {
    parsedTags = tags.join(', ');
  }
  if (statuses.length > 0) {
    parsedStatuses = statuses.join(', \n');
  }

  return {
    statuses: parsedStatuses,
    tags: parsedTags,
    identifier: identifier
  };
};

export const isObjectEmpty = (object) => {
  if (!object) return true;

  return Object.keys(object).length === 0;
};

export const convertMessageToHtmlAndTrimSpaces = (message) => {
  return message.toString('html').replace(/&nbsp;/g, '');
};

export const convertHtmlToString = (value) => {
  if (!value) return '';

  const cleanedString = value.toString('html').replace(/<[^>]+>/g, '');
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = cleanedString;

  return tempElement.value;
};

export const convertToTimeDate = (time) => {
  const parts = time.split(':');
  const date = new Date();
  date.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10), parseInt(parts[2] || 0, 10), 0);

  return date;
};

export const convertDateToServerTimeString = (datetime) => {
  if (!datetime) {
    return datetime;
  }

  const dateObject = new Date(datetime);
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
};

export const truncateFileName = (name, maxLength = 30) => {
  if (name.length <= maxLength) return name;
  const start = name.slice(0, maxLength / 2 - 2);
  const end = name.slice(-maxLength / 2 + 2);
  return `${start}...${end}`;
};
